/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const Bag2IconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M4.5 7.5h15c.5 0 1 .4 1.1.9l1.8 10.8c0 .3 0 .6-.2.9-.2.2-.5.4-.8.4H2.7c-.3 0-.6-.1-.8-.4-.2-.2-.3-.6-.2-.9L3.5 8.4c0-.5.5-.9 1.1-.9zm16.8 12c0-.1-1.8-10.9-1.8-10.9h-15-.1L2.6 19.4h.1c0 .1 18.6.1 18.6.1z" />
		<path d="M17.5 8h-1V6c0-.8-.7-1.5-1.5-1.5H9c-.8 0-1.5.7-1.5 1.5v2h-1V6c0-1.4 1.1-2.5 2.5-2.5h6c1.4 0 2.5 1.1 2.5 2.5v2z" />
	</svg>
))
Bag2IconSvg.displayName = 'Bag2IconSvg'

const Bag2Icon = forwardRef((props, ref) => <Icon component={Bag2IconSvg} ref={ref} {...props} />)
Bag2Icon.displayName = 'Bag2Icon'

Bag2Icon.defaultProps = {
	...Icon.defaultProps,
}
Bag2Icon.propTypes = {
	...Icon.propTypes,
}

export default Bag2Icon
export { Bag2IconSvg }
