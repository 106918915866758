import { styled } from '@mui/system'
import Button from '../button'

const ButtonStyled = styled(Button)`
	.MuiBadge-root {
		.MuiBadge-badge {
			font-size: ${({ theme }) => theme.typography.pxToRem(9)};
			width: calc(var(--spacing) * 1.25);
			height: calc(var(--spacing) * 1.25);
		}
		> .MuiSvgIcon-root {
			color: var(--color-primary);
			font-size: ${({ theme }) => theme.typography.pxToRem(26)};
			${({ theme }) => theme.breakpoints.up('md')} {
				font-size: ${({ theme }) => theme.typography.pxToRem(20)};
			}
		}
		.MuiBadge-badge .MuiSvgIcon-root {
			color: var(--color-primary);
			font-size: var(--font-size-body1);
		}
	}
`

export { ButtonStyled }
